const ynTripListOne = [
    {
      name: "昆明丨丽江丨大理 6天5晚",
      prize: 498,
      linkName: "",
      typeName:'昆明丨丽江丨大理 6天5晚',
      type:'6天5晚',
      typeList:[{
          typeName:'6天5晚'
      }],
      smallImages:'http://dspcloud.spay365.com/98a1a4cdbae87f3b03041ad90a079e8036f27aeb.jpg',
      orderDetialImage:'http://dspcloud.spay365.com/f9d117d33f0b781522bfb6f4e9328d25ce646755.png',
      bannerList: [
        {
          imgUrl:
            "http://dspcloud.spay365.com/bca68f24d72e6da370b71e83e7a448db731a3d7f.png",
        },
        {
          imgUrl:
            "http://dspcloud.spay365.com/78be77f632240b45e5cdd0df73bf4451cb5b9f49.png",
        },
        {
          imgUrl:
            "http://dspcloud.spay365.com/76d702c45a17181faaa1d280ecfc1c00ff7431f8.png",
        },
        {
          imgUrl:
            "http://dspcloud.spay365.com/0e817ab6d547e59ef97b2d379d283a82db3df5a4.png",
        },
      ],
      detailImage: "http://dspcloud.spay365.com/37674936b5e372ad0b50c5d77af17701a2923e15.jpg",
      productNo:'yntrip'
    }
  ]
  
  export default ynTripListOne;
//单独昆明大理丽江产品  